/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import {
  APPOINTMENT_REASON,
  CLIENT_DASHBOARD_DETAILS,
  SETTINGS,
  CLIENT_DOWNLOAD_LIST,
  CLIENT_INSERT_MSG,
  CLIENT_DOWNLOAD_LIST_COUNT,
  ERX_DATA_HTML_NO_DATA,
  ERX_DATA_HTML,
  CLIENT_EXCEL_LIST,
  AGE_VACCINATION_LIST,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_DOCTOR_EDITDATA,
  DOCTOR_SPECIALIZATION,
  SUGGEST_PAT,
  SUGGEST_PAT_MESSAGE,
  DOCTOR_SUBSPECIALIZATION,
  DOCTOR_QUALIFICATION,
  DEPARTMENT_MASTER,
  STATE_MASTER,
  BUCKET_CAPTCHA,
  DOCTOR_SEARCH_ARRAY,
  DOCTOR_SEARCH_DATA,
  ERX_OPERATOR_NOTES,
  resulttitle,
  CHANGE_MOBILENO_RES,
  bloodsugarList,
  SET_DOCTOR_ID_NAME,
  DOCTOR_SUCCESS_MSG,
  SHOW_MESSAGE,
  DASHBOARD_PENDING_PATIENT_DATA,
  DASHBOARD_ERX_DATA,
  DASHBOARD_RX_DETAILS,
  DASHBOARD_ERX_DOC_OBSV,
  DASHBOARD_ERX_LAB_OBSV,
  DASHBOARD_ERX_PATIENT_VITAL,
  DASHBOARD_ERX_DOCPROCEDURE,
  DASHBOARD_ERX_MEDICINE_DETAIL,
  DASHBOARD_GRX_MEDICINE_DETAIL,
  UNIT_DATA,
  VACC_EXCEL_LIST,
  SEARCH_PATIENT_LIST,
  SEARCH_PATIENT_LIST_COUNT,
  CLIENT_ERX_LIST,
  GET_VENDOR_LIST,
  CLIENT_ERX_LIST_COUNT,
  PATIENT_SUCCESS_MSG,
  DELETE_MEDCINE_SUCCESS_MSG,
  ERX_IMG_ARR,
  INVESTIGATION_CATEGORY_DATA,
  REPORT_DETAILS,
  TAG_CATEGORY_DATA,
  PATIENT_LISTS,
  SEARCH_REPORTS_DATA,
  SEARCH_PATIENT_DATA,
  pulseList,
  weightList,
  durationList,
  REFERRALLIST,
  DOSAGELIST,
  bpList,
  tempList,
  spo2List,
  hemoglobinList,
  serumList,
  bloodureaList,
  tlcount,
  //Get user permissions
  USER_PERMISSIONS,
  MEDIA_lIST,
  CLIENT_DEPT_LIST,
  CLIENT_DR_LIST,
  REJECT_REASONS,
  APPOINTMENT_PATIENTS,
  CLIENT_LIST,
  OPERATOR_LIST,
  VENDOR_LIST,
  CLIENT_ERX_TOTAL_FEES,
  GET_CAMERA_LIST,
  GET_CLIENT_MASTERS,
  CLIENT_DASHBOARD_TAT,
  DOCTOR_INSERT_MSG,
  INSERT_OWN_ERX_SUCCESS,
  INSERT_ERROR_INDEX,
  CLIENT_DOCTOR_LIST,
  OPDDIGITIZATION_COUNT,
  OPDDIGITIZATION_MEDICINE,
  DIGITIZATION_DISEASE,
  INVESTIGATION_LAB,
  INVESTIGATION_RADIO,
  CLIENT_PATIENT_VACCLIST,
  CLIENT_PATIENT_VACCTOTAL,
  ONLINE_OFFLINE_STATUS,
  DOCTORTAB_PARAM,
  CLIENT_DASHBOARD_SETTINGS,
  MEDICINE_CATEGORY_COUNT,
  DOCTOR_SPECIALIZATION_COUNT,
  INVESTIGATION_MRI_XRay,
  INVESTIGATION_BLOOD_TEST,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Dashboard.js';
var password = 'd6F3Efeq';
const crypto = require('crypto');
var algorithm = 'aes-256-ctr';
// save doctor api
export const saveDoctorDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/createjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/doctorDtls/create', value)
              .then((data) => {
                dispatch({
                  type: SET_DOCTOR_ID_NAME,
                  payload: {
                    DocID: data.data.body.doctorId,
                    DocName: data.data.body.doctorName,
                    RegisterNo: data.data.body.registerNo,
                    EmailId: data.data.body.email,
                  },
                });
                localStorage.setItem('DocID', data.data.body.ddoctorId);
                localStorage.setItem('DocName', data.data.body.doctorName);
                dispatch({
                  type: GET_DOCTOR_EDITDATA,
                  payload: {},
                });
                dispatch({
                  type: DOCTOR_SUCCESS_MSG,
                  payload: data.data.message,
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Doctor saved successfully',
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'saveDoctorDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'saveDoctorDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'saveDoctorDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'saveDoctorDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending patient information
export const onGetPendingPatInfn = ({userId, patId, userCode, erxid}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, patId, userCode, erxid};
    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/patientDtls/getpatientjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, patId, userCode, erxid, jwtToken};
              jwtAxios
                .post('v1/patientDtls/getpatient', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body[0];
                    dispatch({
                      type: DASHBOARD_PENDING_PATIENT_DATA,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPendingPatInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPendingPatInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingPatInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPendingPatInfn',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending erx information
export const onGeteRxInfn = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxDtlsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              let bodytoken = {userId, erxReqId, jwtToken, userCode};
              // alert(erxReqId)
              jwtAxios
                .post('v1/pateRxMgmt/geteRxDtls', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: DASHBOARD_ERX_DATA,
                      payload: getdata,
                    });

                    //Get Erx image and OCr data
                    if (getdata) {
                      if (getdata.erxImageOCR.length > 0) {
                        var imgurl = [];
                        var imgocr = [];
                        for (var i = 0; i < getdata.erxImageOCR.length; i++) {
                          //Push only images
                          imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
                          //Push Image with ocr data and extension
                          imgocr.push({
                            url: getdata.erxImageOCR[i].erxUrl,
                            ocrdata: getdata.erxImageOCR[i].ocrData,
                            extension: getdata.erxImageOCR[i].urlExtension,
                            rownumber: getdata.erxImageOCR[i].rownumber,
                          });
                        }

                        dispatch({
                          type: ERX_IMG_ARR,
                          payload: imgurl,
                        });
                      }
                    }
                    dispatch({
                      type: GET_DOCTOR_EDITDATA,
                      payload: {},
                    });
                    if (getdata.rxdtls.length > 0) {
                      dispatch({
                        type: DASHBOARD_RX_DETAILS,
                        payload: getdata.rxdtls[0],
                      });
                      dispatch({
                        type: SET_DOCTOR_ID_NAME,
                        payload: {
                          DocID: getdata.rxdtls[0].doctorId,
                          DocName: getdata.rxdtls[0].docName,
                          RegisterNo: getdata.rxdtls[0].registerno,
                          EmailId: getdata.rxdtls[0].email,
                        },
                      });
                    }

                    if (getdata.docobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOC_OBSV,
                        payload: getdata.docobsv[0],
                      });
                    }
                    if (getdata.labobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_LAB_OBSV,
                        payload: getdata.labobsv[0],
                      });
                    }

                    if (getdata.patientvital.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_PATIENT_VITAL,
                        payload: getdata.patientvital[0],
                      });
                    }

                    if (getdata.docprocedure.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOCPROCEDURE,
                        payload: getdata.docprocedure,
                      });
                    }

                    if (getdata.medicinedtls.length > 0) {
                      var arraydata = getdata.medicinedtls.map((e, i) => {
                        return {
                          saltName: e.saltName,
                          medName: e.medName,
                          mn: e.mn,
                          af: e.an,
                          en: e.en,
                          nt: e.nt,
                          unit: e.uomId,
                          unitcode: e.uomId,
                          quantity: e.dosage,
                          refferal: e.referral,
                          route: e.route,
                          advice: e.advice,
                          duration: e.duration,
                          frequency: e.frequency,
                          seqno: e.txnNo,
                        };
                      });
                      dispatch({
                        type: DASHBOARD_ERX_MEDICINE_DETAIL,
                        payload: arraydata,
                      });
                    }
                    if (getdata.genericmedicinedtls.length > 0) {
                      var arraydatageneric = getdata.genericmedicinedtls.map(
                        (e, i) => {
                          return {
                            saltName: e.saltName,
                            medName: e.medName,
                            mn: e.mn,
                            af: e.an,
                            en: e.en,
                            nt: e.nt,
                            unit: e.uomId,
                            unitcode: e.uomId,
                            quantity: e.dosage,
                            refferal: e.referral,
                            refferalHindi: e.referralHindi,
                            route: e.route,
                            advice: e.advice,

                            duration: e.duration,

                            frequency: e.frequency,
                            seqno: e.txnNo,
                          };
                        },
                      );
                      dispatch({
                        type: DASHBOARD_GRX_MEDICINE_DETAIL,
                        payload: arraydatageneric,
                      });
                    }

                    if (getdata.rxdtls.length > 0) {
                      var patientId = getdata.rxdtls[0].patientId;

                      // if (
                      //   getdata.rxdtls[0].doctorId !== '' &&
                      //   getdata.rxdtls[0].doctorId !== '0' &&
                      //   getdata.rxdtls[0].doctorId !== null &&
                      //   getdata.rxdtls[0].doctorId !== undefined
                      // ) {

                      // }
                      dispatch(
                        onGetPendingPatInfn({
                          userId: body.userId,
                          patId: patientId,
                          userCode: body.userCode,
                          erxid: body.erxReqId,
                        }),
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGeteRxInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGeteRxInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxInfn', '0', err),
      );
    }
  };
};
// doctor tab seacrh doctor using mobile No/register
export const onGetSearchArray = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // get jwt token based on input
    jwtAxios
      .post('v1/doctorDtls/searchDoctorRegisterJwt', value)
      .then((data) => {
        try {
          value['jwtToken'] = data.data.body['token'];
          // get the data
          jwtAxios
            .post('v1/doctorDtls/searchDoctorRegister', value)
            .then((data) => {
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: DOCTOR_SEARCH_ARRAY,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: <IntlMessages id='message.somethingWentWrong' />,
                });
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetSearchArray',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchArray',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetSearchArray',
            '0',
            error,
          ),
        );
      });
  };
};

//search match doctor detail,s

// doctor tab seacrh doctor using mobile No/register
export const onGetSearchDoctor = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // get jwt token based on input
    jwtAxios
      .post('v1/doctorDtls/searchDoctorJwt', value)
      .then((data) => {
        try {
          value['jwtToken'] = data.data.body['token'];
          // get the data
          jwtAxios
            .post('v1/doctorDtls/searchDoctor', value)
            .then((data) => {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: DOCTOR_SEARCH_DATA,
                payload: data.data.body,
              });
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetSearchDoctor',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchDoctor',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetSearchDoctor',
            '0',
            error,
          ),
        );
      });
  };
};

//get erxNotes
export const onGeteRxNotes = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getOperatorNotesjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getOperatorNotes', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  const getdata = data.data.body;
                  dispatch({
                    type: ERX_OPERATOR_NOTES,
                    payload: getdata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeteRxNotes',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeteRxNotes',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxNotes',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxNotes', '0', err),
      );
    }
  };
};

//Get dashboard Pending patient information
export const onChangeMobileNo = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/docMgmt/changeDocMobileNojwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/docMgmt/changeDocMobileNo', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.message;
                    dispatch({
                      type: CHANGE_MOBILENO_RES,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: CHANGE_MOBILENO_RES,
                      payload: data.data.message,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPendingPatInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPendingPatInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingPatInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPendingPatInfn',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete eRx Medicine details
export const onDeleteMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/deleteMedicineData', value)
        .then((data) => {
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});

            dispatch({
              type: DELETE_MEDCINE_SUCCESS_MSG,
              payload: data.data.message,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onDeleteMedicineDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onDeleteMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Get Unit data
export const onGetUnits = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getMargUomjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getMargUom', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var arraydata = data.data.body.map((e, i) => {
                    return {
                      title: e.unit,
                    };
                  });
                  dispatch({
                    type: UNIT_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetUnits',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetUnits',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetUnits',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetUnits', '0', err),
      );
    }
  };
};

//Get ClientErxList
export const onGetClientErxList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/reportDtls/getClientErxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/reportDtls/getClientErxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const erxList = data.data.body.data;
                    dispatch({
                      type: CLIENT_ERX_LIST,
                      payload: erxList,
                    });
                    dispatch({
                      type: CLIENT_ERX_TOTAL_FEES,
                      payload:
                        (data.data.body.data &&
                          data.data.body.data.total_fees) ||
                        0,
                    });
                    dispatch({
                      type: CLIENT_ERX_LIST_COUNT,
                      payload:
                        data.data.body.data && data.data.body.data.totalCount,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetClientErxList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetClientErxList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetClientErxList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetClientErxList',
          '0',
          err,
        ),
      );
    }
  };
};

//Update onGetSearchPatientData
export const onGetSearchPatientData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call onGetSearchPatientData  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/searchClientPatientDataJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/pateRxMgmt/searchClientPatientData', value)
              .then((data) => {
                if (data.data.status === 200) {
                  const getdata = data.data.body.data;

                  dispatch({
                    type: SEARCH_PATIENT_LIST,
                    payload: getdata,
                  });
                  dispatch({
                    type: SEARCH_PATIENT_LIST_COUNT,
                    payload:
                      data.data.body && data.data.body.totalCount
                        ? data.data.body.totalCount
                        : 0,
                  });
                  dispatch({type: FETCH_SUCCESS});
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'searchPatientData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'searchPatientData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'searchPatientData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'searchPatientData',
          '0',
          err,
        ),
      );
    }
  };
};

//Get ClientErxList
export const onGetExcelErxList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/reportDtls/getClientErxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/reportDtls/getClientErxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    const erxList = data.data.body.data;
                    dispatch({
                      type: CLIENT_EXCEL_LIST,
                      payload: erxList,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetClientErxList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetClientErxList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetClientErxList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetClientErxList',
          '0',
          err,
        ),
      );
    }
  };
};

//Get ClientErxList
export const onGetVaccExcelErxList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call inprogress list Token  - Get Token
    try {
      jwtAxios
        .post('v1/vaccination/getClientErxListJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogress list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                jwtToken,
              };
              jwtAxios
                .post('v1/vaccination/getClientErxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    const erxList = data.data.body.data;
                    if (body?.process == 'excel') {
                      dispatch({
                        type: VACC_EXCEL_LIST,
                        payload: erxList,
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetVaccExcelErxList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetVaccExcelErxList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetVaccExcelErxList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetVaccExcelErxList',
          '0',
          err,
        ),
      );
    }
  };
};

// get age vaccinatiom details
export const onGetVaccinationDetails = (value) => {
  return (dispatch) => {
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/vaccination/getVaccinationMaster', value)
        .then((data) => {
          if (data.status === 200) {
            dispatch({
              type: AGE_VACCINATION_LIST,
              payload: data?.data?.body?.data || [],
            });
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetVaccinationDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//specialization and doctor specialization event
export const onGetSpecialization = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      var value = body;
      jwtAxios
        .post('v1/doctorDtls/specializationjwt', body)
        .then((data) => {
          try {
            body['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/doctorDtls/specialization', body)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: DOCTOR_SPECIALIZATION,
                    payload: data.data.body.specialization,
                  });
                  dispatch({
                    type: DOCTOR_SUBSPECIALIZATION,
                    payload: data.data.body.subspecialization,
                  });

                  jwtAxios
                    .post('v1/pateRxMgmt/mastersjwt', value)
                    .then((data) => {
                      value['jwtToken'] = data.data.body['token'];
                      jwtAxios
                        .post('v1/pateRxMgmt/masters', value)
                        .then((data) => {
                          dispatch({
                            type: DEPARTMENT_MASTER,
                            payload: data.data.body.deptList,
                          });

                          dispatch({
                            type: DOCTOR_QUALIFICATION,
                            payload: data.data.body.quaList,
                          });
                          dispatch({
                            type: STATE_MASTER,
                            payload: data.data.body.stateList,
                          });
                          dispatch({
                            type: APPOINTMENT_REASON,
                            payload: data.data.body.appointment,
                          });
                          dispatch({
                            type: SETTINGS,
                            payload: data.data.body.settingList,
                          });
                          dispatch({
                            type: pulseList,
                            payload: data.data.body.pulseList,
                          });
                          dispatch({
                            type: weightList,
                            payload: data.data.body.weightList,
                          });
                          dispatch({
                            type: durationList,
                            payload: data.data.body.durationList,
                          });
                          dispatch({
                            type: REFERRALLIST,
                            payload: data.data.body.referralList,
                          });
                          dispatch({
                            type: DOSAGELIST,
                            payload: data.data.body.dosagesList,
                          });
                          dispatch({
                            type: bpList,
                            payload: data.data.body.bpList,
                          });
                          dispatch({
                            type: tempList,
                            payload: data.data.body.tempList,
                          });
                          dispatch({
                            type: spo2List,
                            payload: data.data.body.spo2List,
                          });
                          dispatch({
                            type: hemoglobinList,
                            payload: data.data.body.hemoglobinList,
                          });
                          dispatch({
                            type: serumList,
                            payload: data.data.body.serumList,
                          });
                          dispatch({
                            type: bloodureaList,
                            payload: data.data.body.bloodureaList,
                          });
                          dispatch({
                            type: tlcount,
                            payload: data.data.body.tlcount,
                          });
                          dispatch({
                            type: bloodsugarList,
                            payload: data.data.body.bloodsugarList,
                          });
                          dispatch({
                            type: resulttitle,
                            payload: data.data.body.resulttitle,
                          });

                          dispatch({
                            type: USER_PERMISSIONS,
                            payload:
                              data.data.body.userPermissions &&
                              data.data.body.userPermissions[0] &&
                              data.data.body.userPermissions[0].permission &&
                              data.data.body.userPermissions[0].permission
                                .permission
                                ? data.data.body.userPermissions[0].permission
                                    .permission
                                : {},
                          });
                          dispatch({
                            type: MEDIA_lIST,
                            payload: data.data.body.mediaList,
                          });
                          dispatch({
                            type: REJECT_REASONS,
                            payload: data.data.body.rejectReasons,
                          });
                          dispatch({
                            type: APPOINTMENT_PATIENTS,
                            payload: data.data.body.appointmentPatients,
                          });
                          dispatch({
                            type: CLIENT_LIST,
                            payload: data.data.body.clientList,
                          });
                          dispatch({
                            type: OPERATOR_LIST,
                            payload: data.data.body.operatorList,
                          });
                          dispatch({
                            type: VENDOR_LIST,
                            payload: data.data.body.vendorList,
                          });
                        })
                        .catch((error) => {
                          dispatch({type: FETCH_ERROR, payload: error.message});
                        });
                    })
                    .catch((error) => {
                      dispatch({type: FETCH_ERROR, payload: error.message});
                    });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSpecialization',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSpecialization',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSpecialization',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSpecialization',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetMedialist = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      var value = body;
      jwtAxios
        .post('v1/vendor/specializationjwt', body)
        .then((data) => {
          try {
            body['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/vendor/specialization', body)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  console.log(data.data.body, 'data.data.body');
                  dispatch({
                    type: MEDIA_lIST,
                    payload: data.data.body?.mediaList
                      ? data.data.body?.mediaList
                      : [],
                  });
                  dispatch({
                    type: CLIENT_DEPT_LIST,
                    payload: data.data.body?.deptList
                      ? data.data.body?.deptList
                      : [],
                  });
                  dispatch({
                    type: CLIENT_DR_LIST,
                    payload: data.data.body?.drList
                      ? data.data.body?.drList
                      : [],
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSpecialization',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSpecialization',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSpecialization',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSpecialization',
          '0',
          err,
        ),
      );
    }
  };
};
//Generate Html
export const GenerateERXHTML = (body) => {
  return (dispatch) => {
    dispatch({
      type: ERX_DATA_HTML,
      payload: [],
    });
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      //Call symptom User list data - Get symptom list data using token
      try {
        jwtAxios
          .post('v1/pateRxMgmt/generateHTML', body)
          .then((data) => {
            if (data && data.status && data.status === 200) {
              dispatch({type: FETCH_SUCCESS});
              if (data.data.body) {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: '',
                });
                dispatch({
                  type: ERX_DATA_HTML,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: 'No record found',
                });
              }
            }
          })
          .catch((error) => {
            dispatch({
              type: ERX_DATA_HTML_NO_DATA,
              payload: 'No record found',
            });
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'GetCovidVitalsListData',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({
          type: ERX_DATA_HTML_NO_DATA,
          payload: 'No record found',
        });
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'GetCovidVitalsListData',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({
        type: ERX_DATA_HTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetCovidVitalsListData',
          '0',
          err,
        ),
      );
    }
  };
};
//getClientDashboardDetails
export const getClientDashboardDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getClientDashboardDetailsJwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {};
              bodytoken['jwtToken'] = jwtToken;
              jwtAxios
                .post('v1/reportDtls/getClientDashboardDetails', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: CLIENT_DASHBOARD_DETAILS, //
                      payload: getdata,
                    });

                    dispatch({
                      type: CLIENT_DASHBOARD_TAT,
                      payload: getdata?.enableCheck,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'getClientDashboardDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getClientDashboardDetails',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getClientDashboardDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getClientDashboardDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//get patient list for search dropdown

//getbillingDashboard
export const getbillingDashboard = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getbillingDashboardJwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {};
              bodytoken['jwtToken'] = jwtToken;
              jwtAxios
                .post('v1/reportDtls/getbillingDashboard', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: GET_VENDOR_LIST,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'getbillingDashboard',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getbillingDashboard',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getbillingDashboard',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getbillingDashboard',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetClientDownloadlist = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      var value = body;
      jwtAxios
        .post('v1/reportDtls/getClientDownloadListJwt', body)
        .then((data) => {
          try {
            body['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/getClientDownloadList', body)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: CLIENT_DOWNLOAD_LIST,
                    payload:
                      data.data.body && data.data.body.resultData
                        ? data.data.body.resultData
                        : [],
                  });
                  dispatch({
                    type: CLIENT_DOWNLOAD_LIST_COUNT,
                    payload:
                      data.data.body && data.data.body.totalCount
                        ? data.data.body.totalCount
                        : 0,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetClientDownloadlist',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetClientDownloadlist',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetClientDownloadlist',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetClientDownloadlist',
          '0',
          err,
        ),
      );
    }
  };
};

export const insertClientDownloadLog = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      var value = body;
      jwtAxios
        .post('v1/reportDtls/insertClientLogJwt', body)
        .then((data) => {
          try {
            body['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/insertClientLog', body)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                if (
                  data.data.status === 200 &&
                  data.data.message == 'Success'
                ) {
                  dispatch({
                    type: CLIENT_INSERT_MSG,
                    payload: data.data.body ? data.data.body : '',
                  });
                  dispatch(
                    onGetClientDownloadlist({
                      clientId: body.clientId,
                      userCode: body.userCode,
                      limit: 10,
                      offset: 0,
                    }),
                  );
                } else {
                  dispatch({
                    type: CLIENT_INSERT_MSG,
                    payload: data.data.message ? data.data.message : '',
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'insertClientDownloadLog',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'insertClientDownloadLog',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertClientDownloadLog',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'insertClientDownloadLog',
          '0',
          err,
        ),
      );
    }
  };
};
// //specialization and doctor specialization event
// export const onGetSpecialization = (body) => {
//   return (dispatch) => {
//     dispatch({type: FETCH_START});

//     //Call User list Token  - Get Token
//     try {
//       var value = body;
//       jwtAxios
//         .post('v1/doctorDtls/specializationjwt', body)
//         .then((data) => {
//           try {
//             body['jwtToken'] = data.data.body['token'];
//             jwtAxios
//               .post('v1/doctorDtls/specialization', body)
//               .then((data) => {
//                 if (data.data.status === 200) {
//                   dispatch({type: FETCH_SUCCESS});

//                   dispatch({
//                     type: DOCTOR_SPECIALIZATION,
//                     payload: data.data.body.specialization,
//                   });
//                   dispatch({
//                     type: DOCTOR_SUBSPECIALIZATION,
//                     payload: data.data.body.subspecialization,
//                   });

//                   jwtAxios
//                     .post('v1/pateRxMgmt/mastersjwt', value)
//                     .then((data) => {
//                       value['jwtToken'] = data.data.body['token'];
//                       jwtAxios
//                         .post('v1/pateRxMgmt/masters', value)
//                         .then((data) => {
//                           dispatch({
//                             type: DEPARTMENT_MASTER,
//                             payload: data.data.body.deptList,
//                           });

//                           dispatch({
//                             type: DOCTOR_QUALIFICATION,
//                             payload: data.data.body.quaList,
//                           });
//                           dispatch({
//                             type: STATE_MASTER,
//                             payload: data.data.body.stateList,
//                           });
//                           dispatch({
//                             type: APPOINTMENT_REASON,
//                             payload: data.data.body.appointment,
//                           });
//                           dispatch({
//                             type: SETTINGS,
//                             payload: data.data.body.settingList,
//                           });
//                           dispatch({
//                             type: pulseList,
//                             payload: data.data.body.pulseList,
//                           });
//                           dispatch({
//                             type: weightList,
//                             payload: data.data.body.weightList,
//                           });
//                           dispatch({
//                             type: durationList,
//                             payload: data.data.body.durationList,
//                           });
//                           dispatch({
//                             type: REFERRALLIST,
//                             payload: data.data.body.referralList,
//                           });
//                           dispatch({
//                             type: DOSAGELIST,
//                             payload: data.data.body.dosagesList,
//                           });
//                           dispatch({
//                             type: bpList,
//                             payload: data.data.body.bpList,
//                           });
//                           dispatch({
//                             type: tempList,
//                             payload: data.data.body.tempList,
//                           });
//                           dispatch({
//                             type: spo2List,
//                             payload: data.data.body.spo2List,
//                           });
//                           dispatch({
//                             type: hemoglobinList,
//                             payload: data.data.body.hemoglobinList,
//                           });
//                           dispatch({
//                             type: serumList,
//                             payload: data.data.body.serumList,
//                           });
//                           dispatch({
//                             type: bloodureaList,
//                             payload: data.data.body.bloodureaList,
//                           });
//                           dispatch({
//                             type: tlcount,
//                             payload: data.data.body.tlcount,
//                           });
//                           dispatch({
//                             type: bloodsugarList,
//                             payload: data.data.body.bloodsugarList,
//                           });
//                           dispatch({
//                             type: resulttitle,
//                             payload: data.data.body.resulttitle,
//                           });

//                           dispatch({
//                             type: USER_PERMISSIONS,
//                             payload:
//                               data.data.body.userPermissions &&
//                               data.data.body.userPermissions[0] &&
//                               data.data.body.userPermissions[0].permission &&
//                               data.data.body.userPermissions[0].permission
//                                 .permission
//                                 ? data.data.body.userPermissions[0].permission
//                                     .permission
//                                 : {},
//                           });
//                           dispatch({
//                             type: MEDIA_lIST,
//                             payload: data.data.body.mediaList,
//                           });
//                           dispatch({
//                             type: REJECT_REASONS,
//                             payload: data.data.body.rejectReasons,
//                           });
//                           dispatch({
//                             type: APPOINTMENT_PATIENTS,
//                             payload: data.data.body.appointmentPatients,
//                           });
//                           dispatch({
//                             type: CLIENT_LIST,
//                             payload: data.data.body.clientList,
//                           });
//                           dispatch({
//                             type: OPERATOR_LIST,
//                             payload: data.data.body.operatorList,
//                           });
//                           dispatch({
//                             type: VENDOR_LIST,
//                             payload: data.data.body.vendorList,
//                           });
//                         })
//                         .catch((error) => {
//                           dispatch({type: FETCH_ERROR, payload: error.message});
//                         });
//                     })
//                     .catch((error) => {
//                       dispatch({type: FETCH_ERROR, payload: error.message});
//                     });
//                 } else {
//                   dispatch({
//                     type: FETCH_ERROR,
//                     payload: <IntlMessages id='message.somethingWentWrong' />,
//                   });
//                 }
//               })
//               .catch((error) => {
//                 dispatch({type: FETCH_ERROR, payload: error.message});
//                 dispatch(
//                   onWriteCloudWatchLog(
//                     streamname,
//                     filename,
//                     'onGetSpecialization',
//                     '0',
//                     error,
//                   ),
//                 );
//               });
//           } catch (err) {
//             dispatch(fetchError(err.response.message));
//             dispatch(
//               onWriteCloudWatchLog(
//                 streamname,
//                 filename,
//                 'onGetSpecialization',
//                 '0',
//                 err,
//               ),
//             );
//           }
//         })
//         .catch((error) => {
//           dispatch({type: FETCH_ERROR, payload: error.message});
//           dispatch(
//             onWriteCloudWatchLog(
//               streamname,
//               filename,
//               'onGetSpecialization',
//               '0',
//               error,
//             ),
//           );
//         });
//     } catch (err) {
//       dispatch(fetchError(err.response.message));
//       dispatch(
//         onWriteCloudWatchLog(
//           streamname,
//           filename,
//           'onGetSpecialization',
//           '0',
//           err,
//         ),
//       );
//     }
//   };
// };

export const onGetCameraDashboard = ({
  userId,
  userCode,
  hospitalId,
  clientId,
  agentId,
  isClientPort,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      userId,
      userCode,
      clientId,
      hospitalId,
      agentId,
      isClientPort,
    };
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/getCameraListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                userCode,
                clientId,
                hospitalId,
                agentId,
                jwtToken,
                isClientPort,
              };
              jwtAxios
                .post('v1/cameraDash/getCameraList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_CAMERA_LIST,
                      payload: data.data.body?.data || [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetCameraDashboard',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetClientList = ({
  userId,
  userCode,
  getMapped,
  agentId = '',
  clientId,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode, getMapped, agentId, clientId};
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/cameraDash/getClientMastersListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, jwtToken, userCode, agentId, clientId};
              jwtAxios
                .post('v1/cameraDash/getClientMastersList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_CLIENT_MASTERS,
                      payload: data.data.body?.data?.clientDetails || [],
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetClientList', '0', err),
      );
    }
  };
};

export const uploadDoctor = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      var value = body;
      jwtAxios
        .post('v1/vendor/uploadDoctorJwt', body)
        .then((data) => {
          try {
            body['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/vendor/uploadDoctor', body)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                if (
                  data.data.status === 200 &&
                  data.data.body.status &&
                  !data.data?.body?.status.includes('Error')
                ) {
                  dispatch({
                    type: CLIENT_INSERT_MSG,
                    payload: data.data.body ? data.data.body.status : '',
                  });
                  dispatch({
                    type: DOCTOR_INSERT_MSG,
                    payload: data.data.body ? data.data.body.status : '',
                  });
                } else {
                  let passData = {
                    index: data.data.body?.index ? data.data.body.index : [],
                    Reason: data.data.body?.reason ? data.data.body.reason : [],
                  };
                  dispatch({
                    type: INSERT_ERROR_INDEX,
                    payload: passData,
                  });
                  dispatch({
                    type: DOCTOR_INSERT_MSG,
                    payload: data.data.body?.status
                      ? data.data.body.status
                      : '',
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'uploadDoctor',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'uploadDoctor',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'uploadDoctor',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'uploadDoctor', '0', err),
      );
    }
  };
};

export const getDoctorList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('/v1/doctorDtls/getclientDoctor', body)
        .then((data) => {
          dispatch({type: FETCH_SUCCESS});
          if (data.status === 200) {
            console.info(data?.data?.body, 'data?.data?.body');
            dispatch({
              type: CLIENT_DOCTOR_LIST,
              payload: data?.data?.body ? data?.data?.body : '',
            });
          } else {
            dispatch({
              type: CLIENT_DOCTOR_LIST,
              payload: [],
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'getDoctorList', '0', err),
      );
    }
  };
};

export const ResetDoctorPwd = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      var value = body;
      jwtAxios
        .post('/v1/vendor/DoctorResetPasswordjwt', body)
        .then((data) => {
          try {
            body['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('/v1/vendor/DoctorResetPassword', body)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                if (data.data.status === 200) {
                  dispatch({
                    type: CLIENT_INSERT_MSG,
                    payload: data.data.body ? data.data.body : '',
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getDoctorList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getDoctorList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertClientDownloadLog',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'insertClientDownloadLog',
          '0',
          err,
        ),
      );
    }
  };
};

export const checkDuplicateDoctor = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      var value = body;
      jwtAxios
        .post('v1/vendor/checkDoctorDuplicateJwt', body)
        .then((data) => {
          try {
            body['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/vendor/checkDoctorDuplicate', body)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                if (
                  data.data.status === 200 &&
                  data.data.body.status &&
                  !data.data?.body?.status.includes('Error')
                ) {
                  dispatch({
                    type: CLIENT_INSERT_MSG,
                    payload: data.data.body ? data.data.body.status : '',
                  });
                  dispatch({
                    type: DOCTOR_INSERT_MSG,
                    payload: data.data.body ? data.data.body.status : '',
                  });
                } else {
                  let passData = {
                    index: data.data.body?.index ? data.data.body.index : [],
                    Reason: data.data.body?.reason ? data.data.body.reason : [],
                  };
                  dispatch({
                    type: INSERT_ERROR_INDEX,
                    payload: passData,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'uploadDoctor',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'uploadDoctor',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'uploadDoctor',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'uploadDoctor', '0', err),
      );
    }
  };
};

export const insertUploadErx = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call procedure list Token  - Get Token
    try {
      jwtAxios
        .post('v1/vendor/insertClientErxJwt', body)
        .then((data) => {
          if (data.status === 200) {
            body['jwtToken'] = data.data.body['token'];
            try {
              jwtAxios
                .post('v1/vendor/insertClientErx', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: INSERT_OWN_ERX_SUCCESS,
                      payload: 'success',
                    });
                  } else {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: INSERT_OWN_ERX_SUCCESS,
                      payload: 'failure',
                    });
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'insertUploadErx',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'insertUploadErx',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertUploadErx',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'insertUploadErx', '0', err),
      );
    }
  };
};

const decryptpassword = function ({data}) {
  var decipher = crypto.createDecipher(algorithm, password);
  var dec = decipher.update(data, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return dec;
};

export const ongetEncryptbucketcaptcha = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getUploadPresCredentialsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getUploadPresCredentials', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  let data1 = data.data.body;
                  let res = {
                    type: decryptpassword({data: data1?.type}),
                    accessKey: decryptpassword({data: data1?.accessKey}),
                    secretAccessKey: decryptpassword({
                      data: data1?.secretAccessKey,
                    }),
                    bucketName: decryptpassword({data: data1?.bucketName}),
                    region: decryptpassword({data: data1?.region}),
                    filePath: decryptpassword({data: data1?.filePath}),
                    captchaKey: decryptpassword({data: data1?.captchaKey}),
                  };
                  dispatch({
                    type: BUCKET_CAPTCHA,
                    payload: res,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              });
          } catch (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetEncryptbucketcaptcha',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetEncryptbucketcaptcha',
          '0',
          err,
        ),
      );
    }
  };
};

export const s3UploadErrorEmail = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call procedure list Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/sendWeblinkEmailJwt', body)
        .then((data) => {
          if (data.status === 200) {
            // console.log(data);
            body['jwtToken'] = data.data.body['token'];
            try {
              jwtAxios
                .post('v1/pateRxMgmt/sendWeblinkEmail', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                  } else {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      's3UploadErrorEmail',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  's3UploadErrorEmail',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              's3UploadErrorEmail',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          's3UploadErrorEmail',
          '0',
          err,
        ),
      );
    }
  };
};

export const onSuggestionPatient = ({userId, mobileno, userCode, uhid}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, mobileno, userCode, uhid};
    //Call Update Token  - Get Token
    try {
      jwtAxios.post('v1/vendor/suggestionPatListjwt', body).then((data) => {
        if (data.status === 200) {
          try {
            const jwtToken = data.data.body['token'];
            let bodytoken = {userId, mobileno, jwtToken, userCode, uhid};
            jwtAxios
              .post('v1/vendor/suggestionPatList', bodytoken)
              .then((data) => {
                if (data.data.body) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SUGGEST_PAT,
                    payload: data.data.body
                      ? data.data.body.suggestionlist
                      : '',
                  });
                  dispatch({
                    type: SUGGEST_PAT_MESSAGE,
                    payload: data.data.body?.successMsg || '',
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onSuggestionPatient',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onSuggestionPatient',
                '0',
                err,
              ),
            );
          }
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onSuggestionPatient',
          '0',
          err,
        ),
      );
    }
  };
};

export const insertUploadErxIPD = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call procedure list Token  - Get Token
    try {
      jwtAxios
        .post('v1/vendor/insertClientErxIPDJwt', body)
        .then((data) => {
          if (data.status === 200) {
            body['jwtToken'] = data.data.body['token'];
            try {
              jwtAxios
                .post('v1/vendor/insertClientErxIPD', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: INSERT_OWN_ERX_SUCCESS,
                      payload: 'success',
                    });
                  } else {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: INSERT_OWN_ERX_SUCCESS,
                      payload: 'failure',
                    });
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'insertClientErxIPD',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'insertClientErxIPD',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'insertUploadErx',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'insertUploadErx', '0', err),
      );
    }
  };
};
export const OPDDigitization_chart = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/OPDDigitization_chartJWT', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/OPDDigitization_chart', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: OPDDIGITIZATION_COUNT,
                  payload: data.data.body.reportData[0],
                });
                dispatch({
                  type: OPDDIGITIZATION_MEDICINE,
                  payload: data.data.body.medicineReport,
                });
                //disease chart
                dispatch({
                  type: DIGITIZATION_DISEASE,
                  payload: data.data.body.diseaseReport,
                });
                //investigation lab
                dispatch({
                  type: INVESTIGATION_LAB,
                  payload: data.data.body.labInvesReport,
                });
                //investigation Radiology
                dispatch({
                  type: INVESTIGATION_RADIO,
                  payload: data.data.body.radioInvesReport,
                });
                //medcategory based
                // dispatch({
                //   type: MEDICINE_CATEGORY_COUNT,
                //   payload: data.data.body.medCategoryReport,
                // });
                dispatch({
                  type: DOCTOR_SPECIALIZATION_COUNT,
                  payload: data.data.body.doctorSpecialization,
                });
                //MRI / X-Ray based
                dispatch({
                  type: INVESTIGATION_MRI_XRay,
                  payload: data.data.body.mri_xRayReport,
                });
                dispatch({
                  type: INVESTIGATION_BLOOD_TEST,
                  payload: data.data.body.bloodTestReport,
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'OPDDigitization_chart',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'OPDDigitization_chartJWT',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'OPDDigitization_chartJWT',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'OPDDigitization_chart',
          '0',
          err,
        ),
      );
    }
  };
};

//getClientDashboardDetails
export const getClientVaccineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/vaccination/getClientErxListJwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {};
              bodytoken['jwtToken'] = jwtToken;
              jwtAxios
                .post('v1/vaccination/getClientErxList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    if (value?.process == 'excel') {
                      dispatch({
                        type: VACC_EXCEL_LIST,
                        payload: getdata?.resultData || [],
                      });
                    } else {
                      dispatch({
                        type: CLIENT_PATIENT_VACCLIST, //
                        payload: getdata?.resultData || [],
                      });

                      dispatch({
                        type: CLIENT_PATIENT_VACCTOTAL,
                        payload: getdata?.totalCount || 0,
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'getClientVaccineDetails',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getClientVaccineDetails',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getClientVaccineDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getClientVaccineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

export const storeOnlineOfflineStatus = (value) => {
  return (dispatch) => {
    try {
      jwtAxios
        .post('v1/userMgmt/storeOnlineOfflineJwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogresetUserPaswds list data - Get pending list data using token
            try {
              value['jwtToken'] = data.data.body.token;
              jwtAxios
                .post('v1/userMgmt/storeOnlineOffline', value)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: ONLINE_OFFLINE_STATUS,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'storeOnlineOfflineStatus',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'storeOnlineOfflineStatus',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'storeOnlineOfflineStatus',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'storeOnlineOfflineStatus',
          '0',
          err,
        ),
      );
    }
  };
};

export const getDoctorLogin = (value) => {
  return (dispatch) => {
    let {mobileNo, paswd, credentialId, clientId} = value;
    let tokendoctor = '';
    let paramdata = null;
    try {
      jwtAxios
        .post('v1/docMgmt/loginjwt', {
          mobileNbr: mobileNo,
          paswd: paswd,
        })
        .then((res) => {
          if (res.data.hasOwnProperty.call(res.data, 'message')) {
            if (res.data.message.includes('Error')) {
              let message = res.data.message.split(':');
              console.log(message[1], 'message');
              // dispatch({
              //   type: SIGNIN_ERROR,
              //   payload: message[1],
              // });
            }
          } else {
            // localStorage.setItem('token', res.data.body.token);
            tokendoctor = res.data.body.token;
            var body = {
              mobileNbr: mobileNo,
              paswd: paswd,
              jwtToken: res.data.body.token,
            };
            jwtAxios
              .post('v1/docMgmt/login', body)
              .then((res) => {
                if (res.status === 200) {
                  var response = res.data.body[0];
                  var clientList = response?.generateWordClient;
                  let locArr = [];
                  if (clientList?.length > 0) {
                    clientList.map((item) => {
                      if (item?.clientID) {
                        let id = Number(item?.clientID);
                        locArr.push(id);
                      }
                    });
                  }
                  // dispatch({
                  //   type: WORD_PREVIEW_CLIENT,
                  //   payload: locArr,
                  // });
                  // localStorage.setItem('roleId', '1');
                  let mappedDoctor =
                    response.mappedDoctors && response.mappedDoctors != '0'
                      ? response.mappedDoctors.split(',')
                      : [];
                  mappedDoctor.push(response.doctorCode.toString());
                  let doctordtls = {
                    authType: 'jwt_auth',
                    displayName: response.docname,
                    email: response.email,
                    mobileNo: response.mobileNo,
                    doctorCode: response.doctorCode,
                    roleId: response.doctorCategory,
                    role:
                      response.doctorCategory == 2
                        ? 'Doctor'
                        : 'Premium Doctor',
                    doctorstatus: response.doctorstatus,
                    token: '',
                    settings: response.settings,
                    mappedDoctor: mappedDoctor,
                    doctorcategory: response.doctorCategory,
                    logo: response.doctorProfile,
                    uniqueDocID: response.uniqueDocID,
                    qrCode: response.qrCode,
                    transDate: response.transDate,
                    mrdDrCheck: response.mrdDrCheck == true ? 3 : '',
                    mrdDrcat: response.mrdDrCheck == true ? 'MRD Doctor' : '',
                  };
                  paramdata = {
                    doctorData: doctordtls,
                    WORD_PREVIEW_CLIENT: locArr,
                    tokenDoctor: tokendoctor,
                    clientData: {
                      clientId: clientId,
                      credentialId: credentialId,
                    },
                  };
                  dispatch({
                    type: DOCTORTAB_PARAM,
                    payload: paramdata,
                  });
                } else {
                  if (res.data.message.includes('Error')) {
                    if (res.data.message.includes('1')) {
                      // dispatch({
                      //   type: SIGNIN_ERROR,
                      //   payload: 'Mobile No. or password is incorrect',
                      // });
                    }
                    if (res.data.message.includes('2')) {
                      // dispatch({
                      //   type: SIGNIN_ERROR,
                      //   payload: 'This Mobile No./Email ID is not registered with us',
                      // });
                    }
                  }
                }
              })
              .catch((error) => {
                // dispatch({type: UPDATE_AUTH_USER, payload: error.message});
                // dispatch(
                //   onWriteCloudWatchLog(
                //     streamname,
                //     filename,
                //     'loadJWTUser',
                //     '0',
                //     error,
                //   ),
                // );
                return;
              });
          }
        })
        .catch((error) => {
          // dispatch({
          //   type: FETCH_ERROR,
          //   payload: error.response.data.message.split(':')[1].trim(),
          // });
          // dispatch(
          //   onWriteCloudWatchLog(
          //     streamname,
          //     filename,
          //     'onJwtSignIn',
          //     '0',
          //     error,
          //   ),
          // );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'storeOnlineOfflineStatus',
          '0',
          err,
        ),
      );
    }
  };
};
export const getDashboardSettings = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/settings_chartJWT', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/settings_chart', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: CLIENT_DASHBOARD_SETTINGS,
                  payload: JSON.parse(data.data.body.list),
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'OPDDigitization_chart',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'settings_chartJWT',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'settings_chartJWT',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getDashboardSettings',
          '0',
          err,
        ),
      );
    }
  };
};

export const saveDashboardSettings = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/saveSettings_chartJWT', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/saveSettings_chart', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                console.log(data.data.body, 'save settinsg');
                if (data.data.body.result == 'success') {
                  dispatch({
                    type: CLIENT_DASHBOARD_SETTINGS,
                    payload: JSON.parse(data.data.body.list),
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'saveSettings_chart',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'saveSettings_chartJWT',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'saveSettings_chartJWT',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'saveDashboardSettings',
          '0',
          err,
        ),
      );
    }
  };
};
